<template>
    <div class="card q pb-9" :class="!question.db_id ? 'pointer-event-none' : ''">
        <div class="card-header align-items-center border-0 mt-3">
            <h3 class="title-div card-title align-items-start flex-column">
                <input type="hidden" class="db_id" value="" />
                <div class="comment_box_card">
                    <span
                        id="ques_comment_box_11"
                        class="fw-bolder text-dark fs-3 span-text question content-editable"
                         contenteditable="true"
                        @input="(event) => onInput(event, 'question')"
                         @click="checkInput('question')"
                        @blur="setValue('question')"
                        >{{ question.question }}</span
                    >
                </div>
                <div>
                    <span
                        id="desc_comment_box_11"
                        class="text-gray-400 mt-2 fw-bold fs-6 span-text description content-editable"
                        contenteditable="true"
                        @input="(event) => onInput(event, 'description')"
                        @click="checkInput('description')"
                        @blur="setValue('description')"
                    >
                        {{ question.description }}
                    </span>
                </div>
            </h3>
            <div class="div-top-icon show-on-hover">
                <a
                    href="javascript:void(0)"
                    class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Delete Card"
                    @click="removeQuestion"
                    ><span class="svg-icon svg-icon-3 show-pointer"
                        ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                fill="black"
                            ></path>
                            <path
                                opacity="0.5"
                                d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                fill="black"
                            ></path>
                            <path
                                opacity="0.5"
                                d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                fill="black"
                            ></path></svg></span
                ></a>
            </div>
            <div class="div-bottom-menu show-on-hover">
                <label class="form-check required-checkbox p-2"
                    ><input
                        type="checkbox"
                        class="form-check-input is_required"
                        name="Checkboxes1"
                        @change="up"
                        v-model="question.is_required"
                    /><span></span>Required</label
                >
            </div>
        </div>
        <div class="card-body pt-5">
            <div
                class="form-group row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1 row-cols-xxl-1 g-10"
            >
                <div>
                    <textarea
                        type="text"
                        class="form-control form-control-solid comment_box_input"
                        placeholder="Type here"
                        spellcheck="false"
                    ></textarea>
                    <div>
                        <span
                            id="sublabel_comment_box_11"
                            class="text-gray-400 mt-2 fs-6 span-text sub_label content-editable"
                            contenteditable="true"
                                @input="(event) => onsubinput(event)"
                                >{{ question.sub_label }}</span
                            >
                    </div>
                </div>
            </div>
        </div>
        <img
            class="draggable-handle div-left-icon show-on-hover"
            :src="require('@/assets/images/img/order_drag.svg')"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title="Drag to change order"
        />
        <h1 class="div-left-hd show-on-hover left-count-pos-40 id">{{aIndex}}</h1>
    </div>
</template>
<script>
    import _ from "lodash";
    import QuizMixin from '@/mixin/question.js'
    import { defineComponent, toRefs } from "vue";
    import {mapActions} from "vuex"
    export default defineComponent({
        mixins:[QuizMixin],
        name: "yes-no",
        props: {
            questionData: {
                type: Object,
            },
            mainIndex: {
                type: Number,
            },
        },
        setup(props) {
            const { questionData: question } = toRefs(props);
            const { mainIndex: pindex } = toRefs(props);
            return {
                question,
                pindex,
            };
        },
        data(){
        return {
            aIndex:this.mainIndex+1
        }
    },
        methods: {
            ...mapActions({
                updateSublabel:'Quiz/updateSublabel'
            }),
            onsubinput(event){
                    if (event.target.innerText) {
                        this.question.sub_label= event.target.innerText;
                    } else {
                        this.question.sub_label= "Type here";
                    }
                this.saveOnDataBaseLabel()
            },
                saveOnDataBaseLabel: _.debounce(function () {
                    this.updateQuestionSublabel()
                }, 500),
            updateQuestionSublabel(){
                let data={
                    id:this.question.db_id,
                    sub_label:this.question.sub_label,
                }
                this.updateSublabel(data)
            },
              removeQuestion(){
                  let data={
                      index:this.pindex,
                      id:this.question.db_id
                  }
                    this.$emit('removequestion',data)
                },
            sendupdatedArray() {
                let data = {
                    question: this.question,
                    index: this.pindex,
                };
                this.$emit("updateMainArray", data);
            },
        },
    });
</script>
<style scoped>
.div-bottom-menu{
    position: absolute;
    right: 5px;
    /* top: 8px; */
    bottom: 8px;
    /* background-color: #FFFFFF; */
    padding-left: 35px;
}
.div-top-icon{
    position: absolute;
    right: 5px;
    top: 8px;
    /* bottom: 8px; */
    /* background-color: #FFFFFF; */
    padding-left: 35px;
}

.card:hover  .title-div{
    width: 95% !important;
    word-break: break-word;
}
.btn.btn-bg-light{
 background-color: #f5f8fa;
}
.text-dark.span-text{
    color: #181c32!important;   
}
.form-select, .form-control{
    background-color: #fff;
    border: 1px solid #e4e6ef;
    color: #181c32!important;   
}
.dropdown.show > .form-control.form-control-solid, .form-control.form-control-solid:active, .form-control.form-control-solid.active, .form-control.form-control-solid:focus, .form-control.form-control-solid.focus{
    background-color: #f5f8fa !important;
    border-color: #f5f8fa !important;
    color: #181c32!important;   
}
/* .form-check-input{
    background-color: #fff;
} */
.div-bottom-menu label{
    color: #181c32;
}
.col-form-label, .form-label{
  color: #3f4254;
}
</style>

