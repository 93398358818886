<template>
    <!-- <ul class="nav nav-tabs mt-11" id="myTab" role="tablist">
        <li class="nav-item me-1">
            <a class="nav-link fw-bold custom-nav" @click="setActivetab('all_entries',null)"  :class="active_tab == 'all_entries' ? 'active' : ''" data-bs-toggle="tab" href="#qualified_entries">All Entries</a>
        </li>
        <li class="nav-item me-1">
            <a class="nav-link fw-bold custom-nav" @click="setActivetab('qualified','qualified')"  :class="active_tab == 'qualified' ? 'active' : ''" data-bs-toggle="tab" href="#qualified">Qualified</a>
        </li>
        <li class="nav-item me-1">
            <a class="nav-link  fw-bold custom-nav" @click="setActivetab('disqualified','disqualified')"  :class="active_tab == 'disqualified' ? 'active' : ''" data-bs-toggle="tab" href="#disqualified"> Disqualified</a>
        </li>
    </ul> -->
    <div class="box custom-tabs">
        <div @click="setActivetab('all_entries')"  :class="active_tab == 'all_entries' ? 'active' : ''" class="cursor-pointer">
        All Entries
        </div>
        <span style="font-size: 15px; color: gray;">|</span>
        <div @click="setActivetab('qualified')"  :class="active_tab == 'qualified' ? 'active' : ''" class="cursor-pointer">
        Qualified
        </div>
        <span style="font-size: 15px; color: gray;">|</span>
        <div @click="setActivetab('disqualified')"  :class="active_tab == 'disqualified' ? 'active' : ''" class="cursor-pointer">
        Disqualified
        </div>
    </div>


    <!-- <pre class="text-dark">{{ entriesData }}</pre> -->

    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active">
            <div class="card mb-5 mb-xl-10 rounded-0">
                <div class="card-header">
                    <div class="card-title">
                        <h3>{{ title }}</h3>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-5 col-lg-5 col-sm-12 mt-3">  
                            <div class="row" v-if="body_status">
                                <div class="col-md-6 col-lg-6 col-sm-12">
                                    <input type="text" class="form-control py-2" v-model="filter.query" @input="searchEntries" placeholder="Search by email"><small class="form-text text-muted"><b>Search</b> {{ title }}</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 col-lg-7 col-sm-12">
                            <div class="d-flex flex-wrap justify-content-end">
                                <div class="border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 me-6 mb-3">
                                    <div class="d-flex align-items-center ">
                                        <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                                                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="black" />
                                            </svg>
                                        </span>
                                        <div class="fs-3 fw-bolder text-dark" data-kt-countup="true" :data-kt-countup-value="quizData.views_count" data-kt-countup-prefix="">{{quizData.views_count}}</div>
                                    </div>
                                    <div class="fw-bold fs-7 text-gray-400">Total Views</div>
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 me-6 mb-3">
                                    <div class="d-flex align-items-center">
                                        <span class="svg-icon svg-icon-3 svg-icon-danger me-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="black" />
                                                <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="black" />
                                            </svg>
                                        </span>
                                        <div class="fs-3 fw-bolder text-dark" data-kt-countup="true" :data-kt-countup-value="quizData.entries_count">{{quizData.entries_count}}
                                        </div>
                                    </div>
                                    <div class="fw-bold fs-7 text-gray-400">Entries</div>
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 me-6 mb-3">
                                    <div class="d-flex align-items-center">
                                        <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                                                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="black" />
                                            </svg>
                                        </span>
                                        <div class="fs-3 fw-bolder text-dark" data-kt-countup="true" :data-kt-countup-value="quizData.qualified_entries_count" data-kt-countup-prefix="">{{quizData.qualified_entries_count}}</div>
                                    </div>
                                    <div class="fw-bold fs-7 text-gray-400">Qual ({{quizData.entries_count > 0 ? ((quizData.qualified_entries_count / quizData.entries_count) * 100).toFixed(0) : 0}}%)</div>
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 me-6 mb-3">
                                    <div class="d-flex align-items-center">
                                        <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                                                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="black" />
                                            </svg>
                                        </span>
                                        <div class="fs-3 fw-bolder text-dark" data-kt-countup="true" :data-kt-countup-value="quizData.disqualified_entries_count" data-kt-countup-prefix="">{{quizData.disqualified_entries_count}}</div>
                                    </div>
                                    <div class="fw-bold fs-7 text-gray-400">Disqual ({{quizData.entries_count > 0 ? ((quizData.disqualified_entries_count / quizData.entries_count) * 100).toFixed(0) : 0}}%)</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 ms-0">
                        <ul class="nav">
                            <li class="nav-item">
                                <a class="nav-link btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4 me-1" @click="body_status = true"  :class="body_status == true ? 'active text-white' : 'text-gray-600'" data-bs-toggle="tab" id="all_entries_body_tab">{{ title }}</a>
                            </li>
                            <li class="nav-item" v-if="!body_status">
                                <a class="nav-link btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4 me-1" :class="body_status == false ? 'active text-white' : 'text-gray-600'" data-bs-toggle="tab" id="all_entries_detail_tab">
                                    Detail
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" v-if="body_status == true" id="all_entries_body">
                            <div class="table-responsive pe-7 pt-8">
                                <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                    <thead>
                                        <tr class="fw-bolder text-muted text-uppercase">
                                            <th class="min-w-150px">Respondent</th>
                                            <th class="min-w-150px">Date</th>
                                            <th class="min-w-130px">Email</th>
                                            <th class="min-w-100px">Phone#</th>
                                            <!-- <th class="min-w-80px">Score</th> -->
                                            <th class="min-w-100px">%complete</th>
                                            <th class="min-w-100px">Status</th>
                                            <th class="min-w-100px">Tag</th>
                                            <th class="min-w-100px text-start">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="entriesData && entriesData.data && entriesData.data.length > 0">
                                        <tr id="all_entries_raw_"  v-for="(data, index) in entriesData.data" :key="index">
                                            <td>
                                               <div class="d-flex align-items-center">
                                                    <img :src="api_url+'/assets/img/flags/'+data.countries+'.svg'" v-if="data.countries != null" class="me-4 w-25px" style="border-radius: 4px" alt="">
                                                    <img :src="api_url+'/assets/img/flags/united-states.svg'" v-else class="me-4 w-25px" style="border-radius: 4px" alt="">
                                                    <div class="d-flex justify-content-start flex-column">
                                                        <p class="fw-bolder text-primary fs-6 mb-0">{{data.first_name != '' || data.last_name != '' ? data.first_name+' '+data.last_name : 'No Name'}}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span class="text-gray-700"> {{formatDateTime(data.created_at)}}</span>
                                            </td>
                                            <td>
                                                <a class="fs-6 cursor-pointer" @click="copyEmail(data.email)" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to copy email">{{data.email != '' ? data.email : '--'}}</a>
                                            </td>
                                            <td>
                                                <span class="text-gray-700">{{data.phone != '' ? data.phone : '-'}}</span>
                                            </td>
                                            <td>
                                                <span class="text-gray-700">{{completePercentage(data.responses_count) > 50 ? '100' : '50'}}%</span>
                                            </td>
                                            <td>
                                                <span class="badge badge-lg primary fw-bolder my-2 fs-8 text-capitalize badge-light-green" v-if="data.status == 'qualified'">{{data.status}}</span>
                                                <span class="badge badge-lg primary fw-bolder my-2 fs-8 text-capitalize badge-light-red" v-else-if="data.status == 'disqualified'">{{data.status}}</span>
                                                <span class="badge badge-lg primary fw-bolder my-2 fs-8 text-capitalize badge-light-primary" v-else>{{data.status}}</span>
                                            </td>
                                            <td>
                                                <a class="tag-modal-btn btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"><span class="me-2">
                                                        0</span><i class="fa fa-tag fa-lg"></i>
                                                </a>
                                            </td>
                                            <td>
                                                <div class="d-flex justify-content-start flex-shrink-0">
                                                    <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 view-entiries" @click="viewEntry(data.id)" data-type="all_entries">
                                                        <span class="svg-icon svg-icon-3 cursor-pointer">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                <path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" fill="#A1A5B7" />
                                                            </svg>
                                                        </span>
                                                    </a>
                                                    <a href="#" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm delete-entiries" @click="deleteEntry(data.id,null)" data-type="all_entries">
                                                        <span class="svg-icon svg-icon-3 cursor-pointer">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
                                                                <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
                                                                <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
                                                            </svg>
                                                        </span>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="8">No Entries data Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Pagination :data="entriesData && entriesData.data ? entriesData : []" @pagination-change-page="setEntries" :show-disabled="true" class="mt-7"/>
                            </div>
                        </div>
                        <div class="tab-pane fade show active" v-else id="all_entries_detail">
                          <EntryResponse :entries="entry_data" :responses="response_data"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import moment from "moment";
import { useToast } from "vue-toastification";
import EntryResponse from "@/components/custom-components/quiz/EntryResponse.vue";
import _ from "lodash";
import LaravelVuePagination from 'laravel-vue-pagination';

export default defineComponent({
  components: {
    EntryResponse,'Pagination': LaravelVuePagination
  },
  setup() {
    const toast = useToast();
    return { toast};
  },
  props: {
    quizData: {
      type: Object,
    },
  },
  computed: {
    formatDateTime() {
      return (value) => {
        return moment(value).format("MM/DD/YYYY HH:mm:ss");
      };
    },
    completePercentage() {
      return (value) => {
        return this.total_questions > 0 ? (value / this.total_questions) * 100 : 0;
      };
    },
    title(){
        if(this.filter && this.filter.status == 'all_entries')
            return 'All Entries'
        else if(this.filter && this.filter.status == 'qualified')
            return 'Qualified Entries'
        else if(this.filter && this.filter.status == 'disqualified')
            return 'Disqualified Entries'
        else
            return 'All Entries'
    }
  },
  data() {
    return {
      all_entries: [],
      qualified_entries: [],
      disqualified_entries: [],
      api_url : process.env.VUE_APP_API_URL,
      total_questions : 0,
      total_quiz_response : 0,
      entry_data:[],
      response_data:[],
      all_view_status : true,
      active_tab: 'all_entries',
      body_status : true,
      entry_email_search : '',
      filter : {
        quiz_id : this.$route.params.id,
        page : 1,
        status : 'all_entries',
        query : ''
      },
      entriesData : null
    };
  },
   methods: {
    ...mapActions({
        getEntries: "Quiz/getEntries",
        getEntryResponse: "Quiz/getEntryResponse",
        deleteEntryResponse: "Quiz/deleteEntryResponse",
    }),
    setEntries(page = 1) {
        this.filter.page = page
        this.getEntries(this.filter)
            .then((response) => {
            if (response.data.status == true) {
                // if(status == 'qualified'){
                //   this.qualified_entries = response.data.data.entries_data;
                // }else if(status == 'disqualified'){
                //   this.disqualified_entries = response.data.data.entries_data;
                // }else{
                //   this.all_entries = response.data.data && response.data.data.entries_data ? response.data.data.entries_data : [];
                // }
                this.entriesData = response.data.data && response.data.data.entries_data ? response.data.data.entries_data : null;
                this.total_questions = response.data.data.total_questions;
                this.total_quiz_response = response.data.data.total_quiz_response;
            }
            })
            .catch((error) => {
                this.toast.error(error.response.data.message);
            });
    },
    copyEmail(email){
        if(email){
            document.addEventListener('copy', function(e) {
            e.clipboardData.setData('text/plain', email);
                e.preventDefault();
            }, true);
            document.execCommand('copy');
            this.toast.success('Email Copied','Copied!');
        }
    },
    viewEntry(entry_id){
       this.getEntryResponse(entry_id)
        .then((response) => {
          if (response.data.status == true) {
            this.entry_data = response.data.data.entries;
            this.response_data = response.data.data.responses;
            this.body_status = false;
          }
        })
        .catch((error) => {
          this.toast.error(error.response.data.message);
        });
    },
    deleteEntry(entry_id,status){
       this.deleteEntryResponse(entry_id)
        .then((response) => {
          if (response.data.status == true) {
            this.setEntries(status)
          }
        })
        .catch((error) => {
          this.toast.error(error.response.data.message);
        });
    },
    setActivetab(tab){
        this.filter.page = 1
        this.filter.status = tab
        this.filter.query = ''
        this.active_tab = tab
        this.body_status = true
        this.entry_email_search = ''
        this.setEntries()
    },
    searchEntries: _.debounce(function () {
        this.setEntries();
    }, 500),
  },
  mounted() {
    this.setEntries();
  },
});
</script>
<style>
  .custom-nav {
    padding: 10px 20px 10px 20px !important;
    font-size: 13px;
    border: 1px solid #fff !important;
    color: #000000;
  }

  .custom-top-bar.active {
    background: #fff;
    padding: 5px 10px 5px 10px;
    border: 1px solid #fff;
    border-radius: 5px;
  }

  .custom-nav.active {
    color: #6794dc !important;
  }

  .w-30 {
    width: 30%;
  }

  .w-70 {
    width: 70%;
  }

  .cust-table {
    border: 1px solid #3B3C49 !important;
  }

  .vertical-align-baseline {
    vertical-align: baseline;
  }

  .detail-box {
    background: #3B3C49;
    width: 250px;
    height: 110px;
    padding: 17px 25px 17px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    word-break: break-all;
    margin-right: 22px;
    border-radius: 0.475rem !important;

  }

  .custom-button {
    background: transparent;
    padding: 5px 7px 3px 8px;
    border: 1px solid;
  }
  .badge-light-green {
    color: #04c8c8;
    background-color: #cdf4f4;
  }
  .badge-light-red {
    color: #f1416c;
    background-color: #fcd9e2;
  }
  .lower-section {
    margin: 0px;
    /* border: 1px solid #dadada; */
    padding: 10px 0px;
  }
  .cursor-pointer{
    cursor: pointer !important;
  }

.box {
  background-color: #FFFFFF;
  border-radius: 5px 5px 0px 0px;
  padding: 15px;
}

.custom-tabs {
  color: #000000;
  display: flex;
  align-items: center;
  gap: 15px;
  width: fit-content;
}

.custom-tabs .active {
  color: #E49C35;
  font-weight: 600;
}
</style>
    