<template>
    <div class="card q pb-9" :class="!question.db_id ? 'pointer-event-none' : ''">
        <div class="header-top"></div>
        <div class="card-header align-items-center border-0 percentage-header">
            <h3 class="card-title">
                <div class="info_card">
                    <span id="ques_info_2" class="fw-bolder text-black fs-3 span-text question">Visual Splash</span>
                </div>
            </h3>
            <div class="div-top-icon show-on-hover">
                <!-- <a href="javascript:void(0)"
                    class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm me-4"
                    data-bs-toggle="tooltip" data-bs-placement="bottom" title="Video Library"
                    @click="setVideo">
                    <span class="svg-icon svg-icon-3 show-pointer">
                        <i class="fa fa-video"></i>
                    </span>
                </a> -->
                <a href="javascript:void(0)"
                    class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm"
                    data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete Card"
                    @click="removeQuestion()">
                    <span class="svg-icon svg-icon-3 show-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                fill="black"></path>
                            <path opacity="0.5"
                                d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                fill="black"></path>
                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                fill="black"></path>
                        </svg>
                    </span>
                </a>
            </div>
        </div>
        <div class="card-body">
            <div
                class="form-group row g-10">
                <div v-for="(q,index) in question.inputs" :key="index" class="col-md-5 col-sm-12">
                    <span :id="q.field_for" class="form-label fw-bolder">{{q.field_label}}</span>
                    <input :for="q.field_for" :type="q.type" class="form-control form-control-solid"  @input="(event) => UpdateInputValueI(q)" v-model="q.field_value" />
                </div>
                <div class="col-md-2 col-sm-12">
                    <span for="video_duration" class="form-label fw-bolder">Video Duration</span>
                    <input id="video_duration" type="number" class="form-control form-control-solid" @input="updateQuestionMessageDuration" v-model="question.message_duration"/>
                </div>
            </div>
        </div>
        <img class="draggable-handle div-left-icon show-on-hover" :src="require('@/assets/images/img/order_drag.svg')"
            data-bs-toggle="tooltip" data-bs-placement="left" title="Drag to change order"/>
        <h1 class="div-left-hd show-on-hover left-count-pos-40 id">{{aIndex}}</h1>
    </div>
</template>
<script>
    import QuizMixin from '@/mixin/question.js'
    import { defineComponent, toRefs } from "vue";
    import _ from "lodash";
    import {mapActions} from 'vuex'
    export default defineComponent({
        mixins:[QuizMixin],
        name: "visual-splash",
        props: {
            questionData: {
                type: Object,
            },
            mainIndex: {
                type: Number,
            },
        },
        setup(props) {
            const { questionData: question } = toRefs(props);
            const { mainIndex: pindex } = toRefs(props);
            return {
                question,
                pindex,
            };
        },
        data(){
        return {
            aIndex:this.mainIndex+1
        }
    },
    // mounted(){
    // },
    methods: {
        ...mapActions({
            updateInputValue:'Quiz/addInputValue',
        }),
        UpdateInputValueI: _.debounce(function (q) {
            let data={
                ...q,
                type:this.question.type
            }
            this.sendupdatedArray()
            this.updateInputValue(data)
        }, 500),
        removeQuestion(){
                let data={
                    index:this.pindex,
                    id:this.question.db_id
                }
                this.$emit('removequestion',data)
            },
        sendupdatedArray() {
            let data = {
                question: this.question,
                index: this.pindex,
            };
            this.$emit("updateMainArray", data);
        },
        setVideo(){
            this.$emit("setVisualData",this.question);
        }
    },
});
</script>

<style scoped>
.div-bottom-menu{
    position: absolute;
    right: 5px;
    /* top: 8px; */
    bottom: 8px;
    /* background-color: #FFFFFF; */
    padding-left: 35px;
}
.div-top-icon{
    position: absolute;
    right: 5px;
    top: 8px;
    /* bottom: 8px; */
    /* background-color: #FFFFFF; */
    padding-left: 35px;
}

.card:hover  .title-div{
    width: 80% !important;
    word-break: break-word;
}
.btn.btn-bg-light{
 background-color: #f5f8fa;
}
.text-dark.span-text{
    color: #181c32!important;   
}

/* .form-check-input{
    background-color: #fff;
} */
.div-bottom-menu label{
    color: #181c32;
}
.col-form-label, .form-label{
  color: #3f4254;
}
.form-select, .form-control{
    background-color: #fff;
    border: 1px solid #e4e6ef;
    color: #181c32!important;   
}
.dropdown.show > .form-control.form-control-solid, .form-control.form-control-solid:active, .form-control.form-control-solid.active, .form-control.form-control-solid:focus, .form-control.form-control-solid.focus{
    background-color: #f5f8fa !important;
    border-color: #f5f8fa !important;
    color: #181c32!important;   
}
.percentage-header{
    min-height: 50px !important;
}

.header-top{
    background-color: #2477ff !important;
    border-radius: 10px 10px 0px 0px;
    height: 8px;
}
</style>