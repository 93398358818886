<template>
    <div class="row mt-8">
        <div class="col-12 d-flex justify-content-between align-items-center">
            <div class="d-flex flex-column">
                <div class="d-flex align-items-center mb-2">
                    <a  class="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">{{entries.first_name}} {{entries.last_name}}</a>
                </div>
                <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 me-3">
                    <a class="d-flex align-items-center text-gray-400 text-hover-primary mb-2 me-4" v-if="entries.email">
                        <span class="svg-icon svg-icon-4 me-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="black"></path>
                                <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="black"></path>
                            </svg>
                        </span>
                        {{entries.email}}
                    </a>
                    <a class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2" v-if="entries.phone">
                      <span class="svg-icon svg-icon-4 me-1"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo8/dist/../src/media/svg/icons/Devices/Phone.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"/>
                                <path d="M7.13888889,4 L7.13888889,19 L16.8611111,19 L16.8611111,4 L7.13888889,4 Z M7.83333333,1 L16.1666667,1 C17.5729473,1 18.25,1.98121694 18.25,3.5 L18.25,20.5 C18.25,22.0187831 17.5729473,23 16.1666667,23 L7.83333333,23 C6.42705272,23 5.75,22.0187831 5.75,20.5 L5.75,3.5 C5.75,1.98121694 6.42705272,1 7.83333333,1 Z" fill="#000000" fill-rule="nonzero"/>
                                <polygon fill="#000000" opacity="0.3" points="7 4 7 19 17 19 17 4"/>
                                <circle fill="#000000" cx="12" cy="21" r="1"/>
                            </g>
                        </svg>
                      </span>
                        {{entries.phone}}
                    </a>
                </div>
            </div>
            <!-- <div class="link-icon">
                <i class="fas fa-external-link-alt fa-lg"></i>
            </div> -->
        </div>
    </div>
    <div class="table-responsive">
        <table class="table cust-table table-row-bordered table-striped table-bordered table-border align-middle gy-4 gs-9">
            <thead class="">
                <tr class="fw-bolder">
                    <th class="fs-5 w-30 ps-2">Question</th>
                    <th class="fs-5 w-70">Response</th>
                </tr>
            </thead>
            <tbody>
            <tr v-for="(res,index) in responses" :key="index">
                <td class="w-30 fs-6 fw-bolder vertical-align-baseline ps-2">{{res.question}}</td>
                <td class="w-70 fs-6 pt-1 pb-1" v-if="isArrayCheck(res.response)">
                    <p class="mb-1">Name - {{res.response.name}}</p>
                    <p class="mb-1">Email - {{res.response.email}}</p>
                    <p class="mb-1">phone - {{res.response.phone}}</p>
                </td>
                <td class="w-70 fs-6 pt-1 pb-1" v-else>{{res.response}}</td>
            </tr>
        </tbody>
        </table>
    </div>
    <div class="row mb-10 lower-section">
        <div class="col-md-9 col-sm-12 col-lg-9 overflow-auto d-flex px-0">
            <div class="detail-box">
                <p class="fs-7 mb-1">Created</p>
                <p class="fs-5 fw-bolder mb-1">{{formatDate(entries.created_at)}}</p>
                <p class="fs-7">{{formatTime(entries.created_at)}}</p>
            </div>
            <div class="detail-box">
                <p class="fs-7 mb-1">IP Address</p>
                <p class="fs-5 fw-bolder mb-1">{{entries.ip_address}}</p>
            </div>
            <div class="detail-box">
                <p class="fs-7 mb-1">Submission Status</p>
                <p class="fs-5 fw-bolder mb-1 text-capitalize">{{entries.status}}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";
import moment from "moment";

export default defineComponent({
  setup() {
    const toast = useToast();
    return { toast};
  },
  props: {
    entries: {
      type: Object,
    },
    responses: {
      type: Array,
    },
  },
  // created() {
  //   console.log("this.entries",this.entries)
  //   console.log("this.responses",this.responses)
  // },
  computed: {
    formatDate() {
      return (value) => {
        return moment(value).format("MM/DD/YYYY");
      };
    },
    formatTime() {
      return (value) => {
        return moment(value).format("HH:mm:ss");
      };
    },
  },
  methods: {
    isArrayCheck(array){
      if (array === null) { return false;}
      return ( (typeof array === 'function') || (typeof array === 'object') );
    }
  },
});
</script>
    