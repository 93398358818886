<template>
  <div class="card mb-9">
    <div class="card-body pt-9 pb-0">
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <div class="flex-grow-1 overflow-auto">
              <div
            class="
              d-flex
              justify-content-between
              align-items-start
              flex-wrap
              mb-2
            "
          >
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center mb-2">
                <a
                  href="#"
                  class="text-gray-900 text-hover-primary fs-2 fw-bolder me-1 tw-text-2xl"
                  >{{quizData.name}}</a
                >
              </div>
              <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                <a
                  href="#"
                  class="
                    d-flex
                    align-items-center
                    text-gray-400 text-hover-primary
                    me-5
                    mb-2
                  "
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                        fill="black"
                      ></path>
                      <path
                        d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                        fill="black"
                      ></path>
                    </svg>
                  </span>
                  {{quizData.entries_count}} Entries
                </a>
                <a
                  href="#"
                  class="
                    d-flex
                    align-items-center
                    text-gray-400 text-hover-primary
                    me-5
                    mb-2
                  "
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                        fill="black"
                      ></path>
                      <path
                        d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                        fill="black"
                      ></path>
                    </svg>
                  </span>
              {{quizData.total_qualified}} Qualified ({{quizData.quizData > 0 ? Math.round((quizData.total_qualified / quizData.entries_count) * 100,0) : 0}}%)

                </a>
              </div>
            </div>
            <div class="d-flex my-4">
              <div class="me-0">
                <button
                @click="copyLink"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                >
                  <i class="fa fa-copy fs-5"></i>
                </button>
                <!-- <div
                  class="
                    menu
                    menu-sub
                    menu-sub-dropdown
                    menu-column
                    menu-rounded
                    menu-gray-800
                    menu-state-bg-light-primary
                    fw-bold
                    w-200px
                    py-3
                  "
                  data-kt-menu="true"
                >
                  <div class="menu-item px-3">
                    <div
                      class="
                        menu-content
                        text-muted
                        pb-2
                        px-3
                        fs-7
                        text-uppercase
                      "
                    >
                      Copy Links
                    </div>
                  </div>
                  <div class="menu-item px-3">
                    <a class="menu-link px-3 copy-link-btn" @click="copyLink"
                      >Quiz URL</a
                    >
                  </div>
                  <div class="menu-item px-3">
                    <a class="menu-link px-3 copy-script-btn" @click="copyScript"
                      >Lightbox Script</a
                    >
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex overflow-auto h-55px border-top border-1">
        <div class="separator"></div>
        <ul
          class="
            nav nav-stretch nav-line-tabs nav-line-tabs-2x
            border-transparent
            fs-5
            fw-bolder
            flex-nowrap
          "
        >
          <li class="nav-item">
            <a
              class="nav-link text-active-primary me-6"
              :class="active_tab==1 ? 'active' : ''"
              href="javascript:void(0)"
              @click="changeTab(1)"
            >
              Questionaire
            </a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link text-active-primary me-6"
              :class="active_tab==2 ? 'active' : ''"
              href="javascript:void(0)"
              @click="changeTab(2)"
            >
              Theme
            </a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link text-active-primary me-6"
              href="javascript:void(0)"
              :class="active_tab==3 ? 'active' : ''"
              @click="changeTab(3)"
            >
              Redirects
            </a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link text-active-primary me-6"
              href="javascript:void(0)"
              :class="active_tab==4 ? 'active' : ''"
              @click="changeTab(4)"
            >
              Automation
            </a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link text-active-primary me-6"
              :class="active_tab==8 ? 'active' : ''"
              href="javascript:void(0)"
              @click="changeTab(8)"
              style="white-space: pre;"
              s
            >
              Jump Logic
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-active-primary me-6"
              :class="active_tab==5 ? 'active' : ''"
              href="javascript:void(0)"
              @click="changeTab(5)"
            >
              Publish
            </a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link text-active-primary me-6"
              :class="active_tab==6 ? 'active' : ''"
              href="javascript:void(0)"
              @click="changeTab(6)"
            >
              Entries
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-active-primary me-6"
              :class="active_tab==7 ? 'active' : ''"
              href="javascript:void(0)"
              @click="changeTab(7)"
            >
              Statistics
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { useToast } from "vue-toastification";
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const toast = useToast();
    return { toast};
  },
  name:"tab-top-bar",
   props:{
    quizData:{
      type:Object
    },
    active_tab:{
        default:1
    }
  },
  methods:{
      changeTab(tab){
          this.$emit('changeTab',tab)
      },
      copyLink(){
        let copyUrl = process.env.VUE_APP_API_URL+"/page/quiz/"+this.quizData.unique_code
        document.addEventListener('copy', function(e) {
        e.clipboardData.setData('text/plain', copyUrl);
            e.preventDefault();
        }, true);
        document.execCommand('copy');
        this.toast.success('Link Copied Successfully','Copied!');
        window.open(copyUrl, '_blank');
      },
      copyScript(){
        let copyUrl = process.env.VUE_APP_API_URL+"/page/quiz/"+this.quizData.unique_code
        var scriptRoute =  process.env.VUE_APP_API_URL+'/assets/quiz/script.js'
        // eslint-disable-next-line no-useless-escape
        var iFrame = '<iframe id="QuizForma-'+this.quizData.unique_code+'" onload="window.parent.scrollTo(0,0)" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src="'+copyUrl+'" frameborder="0" width="100%" height="539px" scrolling="no"></iframe><script type="text/javascript" src="'+scriptRoute+'" quiz-unique-code="'+this.quizData.unique_code+'"></\script>'
        document.addEventListener('copy', function(e) {
            e.clipboardData.setData('text/plain', iFrame);
            e.preventDefault();
        }, true);
        document.execCommand('copy');
        this.toast.success('Script Copied Successfully','Copied!');
      }
  }
})
</script>

