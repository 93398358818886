<template>
    <div id="scoring_yes_no_exist_258" class="card card-xl-stretch mb-5 mb-xl-10 card-full">
        <div class="card-header">
            <div class="d-flex flex-wrap gap-1">
                <h3 class="card-title fw-bolder align-items-start flex-column">Score</h3>
                <img :src="require('@/assets/images/img/question_mark_icon.svg')">
            </div>
            <div class="card-toolbar">
                <span class="svg-icon svg-icon-primary svg-icon-2x show-pointer" @click="$emit('clearSelected')">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                        height="24px" viewBox="0 0 24 24" stroke="none" version="1.1">
                        <g stroke="#A1A5B7" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                                fill="#A1A5B7">
                                <rect x="0" y="7" width="16" height="2" rx="1"></rect>
                                <rect opacity="0.3"
                                    transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                                    x="0" y="7" width="16" height="2" rx="1"></rect>
                            </g>
                        </g>
                    </svg>
                </span>
            </div>
        </div>
        <div class="card-body pb-0">
            <div class="mb-0" id="scoring_yes_no_data_exist_258">
                <!-- <div class="form-group row mt-4 scoring_yes_no_div" v-for="(i,index) in question.inputs" :key="index">
                    <label class="col-7 col-form-label yes-text">{{i.field_label}}</label>
                </div> -->

                <!-- <div>
                    <p>Scoring category</p>
                    <select class="form-select" v-model="selectOption">
                        <option :value="null" selected>Select</option>
                        <option :value="i.id" v-for="(i, index) in question.inputs" :key="index">{{ i.field_label }}</option>
                    </select>
                </div>

                <div>

                </div> -->
                <div class="d-flex align-items-start justify-content-between text-black mb-5">
                    <p class="text-lg mb-0 fw-bold">Category</p>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked
                            v-model="isShowCategory" @change="changeCategoryStatusDebounce">
                    </div>
                </div>

                <div v-if="isShowCategory" class="pt-6 px-6 border my-6 rounded shadow-sm" >
                    <h5>Choose your category for this questions:</h5>
                    <select class="form-select my-6" @change="scoreCategoryDebounce($event.target.value)">
                        <option :value="category.id" v-for="(category, index) in allCategory" :key="index" :selected="category.id == data.score_category_id">
                            {{ category.name}}
                        </option>
                    </select>
                </div>

                <hr class="text-black" style="opacity: .1;" >

                <div class="pt-6 px-6 border my-6 rounded shadow-sm" v-if="question.inputs.length" >
                    <h5>Scores:</h5>
                    <div class="mb-6" :class="question.inputs.length - 1 == index ? '' : ''"
                        v-for="(i, index) in question.inputs" :key="index">
                        <p class="fw-bold text-black mb-2" >{{ i.field_label }}</p>
                        <!-- <div class="pt-2 mb-3" v-if="isShowCategory">
                        <p class="fw-bold text-black mb-1">Scoring category</p>
                        <select class="form-select"
                            @change="selectOption[index] = $event.target.value, addScoreActionDebounce(i, $event.target.value, score[index] ? score[index] : i.score_point)">
                            <option :value="null" :selected="!i.score_category">Select</option>
                            <option :value="category.id" v-for="(category, index) in allCategory"
                                :selected="i.score_category == category.id" :key="index">{{ category.name }}
                            </option>
                        </select>
                    </div> -->
                        <div>
                            <input type="number" class="form-control"
                                @input="score[index] = $event.target.value, addScoreActionDebounce(i, selectOption[index], $event.target.value)"
                                :value="i.score_point">
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import _ from "lodash";
import { defineComponent, toRefs } from "vue";
export default defineComponent({
    name: "Score-box",
    props: {
        data: {
            type: Object,
        },
        quizId: Number,
    },
    setup(props) {
        const { data: question } = toRefs(props);

        return {
            question,
        };
    },
    data() {
        return {
            selectOption: [],
            selectModel: null,
            score: [],
            allCategory: [],
            isShowCategory: true,
        }
    },
    methods: {
        ...mapActions({
            updateDisqualifier: 'Quiz/updateDisqualifier',
            getQuizCategory: 'Quiz/getQuizCategory',
            saveQuestionInput: 'Quiz/saveQuestionInput',
            changeScoreCategory: 'Quiz/changeScoreCategory',
            changeScoreCategoryId: 'Quiz/changeScoreCategoryId',
        }),
        updateData(t) {
            let data = {
                db_id: t.db_id,
                disqualify: t.disqualify
            }
            this.updateDisqualifier(data)
        },
        getCategory() {
            this.getQuizCategory({ quiz_id: this.quizId })
                .then(
                    (res) => {
                        if (res.data.status) {
                            this.allCategory = res.data.data
                        }
                    }
                ).catch((error) => {
                    this.toast.error(error.message);
                });
        },
        addScoreActionDebounce: _.debounce(function (input, category, score) {
            this.addScoreAction(input, category, score);
        }, 800),
        addScoreAction(input, category, score) {
            let payload = {
                input: input,
                score_category: this.isShowCategory ? parseInt(category) : null,
                score_point: parseInt(score) || 0,
            }

            this.saveQuestionInput(payload)
                .then().catch((error) => {
                    this.toast.error(error.message);
                });
        },
        changeCategoryStatusDebounce: _.debounce(function () {
            this.changeCategoryStatus();
        }, 800),
        scoreCategoryDebounce: _.debounce(function (value) {
            this.changeCategoryId(value);
        }, 800),
        changeCategoryStatus() {
            let payload = {
                id: this.data.id,
                isShowCategory: this.isShowCategory
            }

            this.changeScoreCategory(payload)
                .then(
            ).catch((error) => {
                this.toast.error(error.message);
            });
        },
        changeCategoryId(id) {
            let payload = {
                id: this.data.id,
                category: id
            }

            this.changeScoreCategoryId(payload)
                .then(
            ).catch((error) => {
                this.toast.error(error.message);
            });
        }
    },
    mounted() {
        // console.log(this.question)
        this.getCategory()
        this.isShowCategory = this.data.is_score_category ? true : false
    },

});
</script>

<style scoped>
.card-full {
    position: fixed !important;
    width: inherit;
    overflow-y: auto;
    max-width: 21%;
    border-radius: 5px !important;
    height: fit-content;
    max-height: 75%;
}
</style>