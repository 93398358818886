<template>
    <div id="tagging_yes_no_exist_258" class="card card-xl-stretch mb-5 mb-xl-10 scr-tag-div-fixed">
        <div class="card-header">
            <div class="d-flex flex-wrap gap-1">
                <h3 class="card-title fw-bolder align-items-start flex-column">Tagging</h3>
                <img :src="require('@/assets/images/img/question_mark_icon.svg')">
            </div>
            <div class="card-toolbar">
                <span class="svg-icon svg-icon-secondary svg-icon-2x show-pointer"
                    @click="$emit('clearSelected')">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                        height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="#A1A5B7" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                                fill="#A1A5B7">
                                <rect x="0" y="7" width="16" height="2" rx="1"></rect>
                                <rect opacity="0.3"
                                    transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                                    x="0" y="7" width="16" height="2" rx="1"></rect>
                            </g>
                        </g>
                    </svg></span>
            </div>
        </div>
        <div class="card-body py-3">
            <div class="mb-0" id="tagging_yes_no_data_exist_258">
                <div class="form-group row mt-4 tagging_yes_no_div" style="border-bottom: 1px solid #f0f0f0;padding-bottom:15px" v-for="(i,index) in question.inputs" :key="index">
                    <label for="example-password-input" class="col-5 col-form-label yes-text">{{i.field_label}}</label>
                    <div class="col-7">
                        <select class="form-select form-control form-control-solid tag_select" v-model="i.tag_id" @change="updateTagIdData(i)">
                            <option :value="null">Select Tags</option>
                            <option v-for="(tag, index) in getTagsData" :key="index" :value="tag._id"> {{ tag.name }} </option>
                        </select>
                    </div>
                    <!-- <div class="col-1">
                        <img class="col-form-label" :src="require('@/assets/images/img/question_mark_icon.svg')">
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineComponent, toRefs } from "vue";
    import {mapActions,mapGetters} from "vuex"
    import { useToast } from "vue-toastification";
    export default defineComponent({
        name: "Score-box",
        props: {
            data: {
                type: Object,
            },
        },
        setup(props) {
            const { data: question } = toRefs(props);
                const toast = useToast();
            return {
                question,
                toast
            };
        },
    computed:{
        ...mapGetters({
        getTagsData:'GC/getTagsData'
        }),
    },
    methods:{
        ...mapActions({
            updateTagId:'Quiz/updateTagId'
        }),
        updateTagIdData(i){
            this.updateTagId(i).then(()=>{
                this.toast.success('Tag added.');
            }).catch((error)=>{
                this.toast.error(error.response.data.message);
            })
        }
    }
    });
</script>