<template>
   <!-- <pre class="text-black">{{ hiddenQuestions }}</pre> -->
   <div class="row mt-11">
      <div class="col-sm-12" :class="showjump==true ? 'col-lg-7 col-md-7 col-xl-7 col-xs-7' : 'col-lg-12 col-md-12 col-xl-12 col-xs-12' ">
         <div class="card mb-4 position-relative" v-for="(j,index) in jump_main_array" :key="index">
            <div class="card-body">
               <div class="row" v-if="!showjump">
                  <div class="col-lg-7 col-md-6 col-xl-7 col-sm-12 col-xs-12">
                     <div class="row">
                        <div class="col-md-12 col-xl-6 col-lg-6 col-sm-12 col-xs-12 border-right-color border-bottom-color d-flex align-items-center">
                           <div class="d-flex flex-column">
                              <!--begin::Name-->
                              <div class="d-flex align-items-center mb-2">
                                 <a class="text-gray-900 text-hover-primary fs-2 fw-bold me-1">{{index + 1}}. {{j.question}}</a>
                              </div>
                              <!--end::Name-->
                              <!--begin::Info-->
                              <div class="d-flex flex-wrap fw-bold fs-6 pe-2">
                                 <a 
                                    class="d-flex align-items-center text-gray-600 text-hover-primary me-5 mb-2">
                                    <!--begin::Svg Icon | path: icons/duotune/communication/com006.svg-->
                                    <span class="svg-icon svg-icon-4 me-1">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                          viewBox="0 0 24 24" fill="none">
                                          <path opacity="0.3"
                                             d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                                             fill="black"></path>
                                          <path
                                             d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                                             fill="black"></path>
                                       </svg>
                                    </span>
                                    {{j.logic_answer.length}} Jumps
                                 </a>
                              </div>
                              <!--end::Info-->
                           </div>
                        </div>
                        <div class="col-md-12 col-xl-6 col-lg-6 col-sm-12 col-xs-12 border-right-color border-bottom-color ps-lg-10 margin-bottom-rs">
                           <a class="text-gray-900 text-hover-primary fs-3 fw-bold mb-2">Selected inputs</a>
                           <div class="d-flex flex-wrap mt-3">
                              <div class="form-check me-2 mb-2" :class="j.question_type != 'options-question' ? 'form-check-custom border cust-cb-border-color pe-3' : ''" v-for="(jp,jindex) in j.logic_answer" :key="jindex"><input
                                 class="form-check-input radio_disable_click" name="option_radio_3"
                                 type="radio" value="" v-if="j.question_type == 'options-question'"><span id="options_3_0"
                                 class="form-check-label cursor-text span-text option_data content-editable text-gray-600 fw-bold">{{jp.field_label}}</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-5 col-md-6 col-xl-5 col-sm-12 col-xs-12 ps-lg-10">
                     <div class="row">
                        <div class="col-md-12 col-lg-5 col-sm-6 col-xs-12 col-xl-5 margin-bottom-rs">
                           <div class="mb-2">
                              <a class="text-gray-900 text-hover-primary fs-3 fw-bold me-1">Jump To :</a>
                           </div>
                           <span class="mt-2 text-gray-600 text-gray-600 fw-bold">Jump Question #{{j.jump_question_id}}</span>
                           <br>
                           <span class="text-gray-600 text-gray-600 fw-bold">{{j.jump_question}}</span>
                        </div>
                        <div class="col-md-12 col-lg-6 col-sm-6 col-xs-12 col-xl-6">
                           <div class="mb-2">
                              <a class="text-gray-900 text-hover-primary fs-3 fw-bold me-1">Next Question : </a>
                           </div>
                           <span class="mt-2 text-gray-600 fw-bold">{{j.next_action_type==1 ? 'Go to next question' : j.next_action_type==2 ? 'Go to question #' : 'End Quiz'}}</span>
                           <br>
                           <span v-if="j.next_action_type == 2" class="text-gray-600 fw-bold">{{j.next_question}}</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="row" v-else>
                  <div class="col-6 border-right-color">
                     <div class="d-flex flex-column">
                        <!--begin::Name-->
                        <div class="d-flex align-items-center mb-2">
                           <a class="text-gray-900 text-hover-primary fs-2 fw-bold me-1">{{j.question_id}}. {{j.question}}</a>
                        </div>
                        <!--end::Name-->
                        <!--begin::Info-->
                        <div class="d-flex flex-wrap fw-bold fs-6 pe-2">
                           <a 
                              class="d-flex align-items-center text-gray-600 text-hover-primary me-5 mb-2">
                              <!--begin::Svg Icon | path: icons/duotune/communication/com006.svg-->
                              <span class="svg-icon svg-icon-4 me-1">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3"
                                       d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                                       fill="black"></path>
                                    <path
                                       d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                                       fill="black"></path>
                                 </svg>
                              </span>
                              {{j.logic_answer.length}} Jumps
                           </a>
                        </div>
                        <div class="row align-items-center text-muted ps-5" v-for="(jp,jindex) in j.logic_answer" :key="jindex">
                           <div class="col-1 pt-3 fw-bolder white-space-pre">{{jindex == 0 ? '=' : 'Or'}}</div>
                           <div id="option_div_3_0" class="col-10 mt-5">
                              <div class="form-check form-check-custom border cust-cb-border-color p-4"><input
                                 class="form-check-input radio_disable_click" v-if="j.question_type == 'options-question'" name="option_radio_3"
                                 type="radio" value=""><span id="options_3_0"
                                 class="form-check-label cursor-text span-text option_data content-editable text-gray-600">{{jp.field_label}}</span>
                              </div>
                           </div>
                        </div>
                        <!--end::Info-->
                     </div>
                  </div>
                  <div class="col-6 ps-10">
                     <div class="row">
                        <div class="col-12 mb-10">
                           <div class="mb-2">
                              <a class="text-gray-900 text-hover-primary fs-3 fw-bold me-1">Jump To :</a>
                           </div>
                           <span class="mt-2 text-gray-600 fw-bold">Jump Question #{{j.jump_question_id}}</span>
                           <br>
                           <span class="text-gray-600 fw-bold">{{j.jump_question}}</span>
                        </div>
                        <div class="col-12">
                           <div class="mb-2">
                              <a class="text-gray-900 text-hover-primary fs-3 fw-bold me-1">Next Question : </a>
                           </div>
                           <span class="mt-2 text-gray-600 fw-bold">{{j.next_action_type==1 ? 'Go to next question' : j.next_action_type==2 ? 'Go to question #' : 'End Quiz'}}</span>
                           <br>
                           <span v-if="j.next_action_type == 2" class="text-gray-600 fw-bold">{{j.next_question}}</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="right-arrow" @click="editjump(j,index)">
               <i class="fa fa-angle-left fa-lg"></i>
            </div>
            <div class="button-delete">
               <a href="javascript:void(0)" @click="deleteJump(j,index)" class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete Card">
                  <span class="svg-icon svg-icon-3 show-pointer">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black"></path>
                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black"></path>
                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black"></path>
                     </svg>
                  </span>
               </a>
            </div>
         </div>
         <div class="row">
            <div class="col-12 text-end text-primary fw-bold fs-4 pe-4 cursor-pointer" @click="addNewCondition()">
               Add Condition
            </div>
         </div>
      </div>
      <div class="col-sm-12 col-lg-5 col-md-5 col-xl-5" v-if="showjump">
         <div class="card mb-5 mb-xl-10">
            <!--begin::Card header-->
            <div class="card-header border-0 cursor-pointer">
               <!--begin::Card title-->
               <div class="card-title m-0">
                  <h3 class="fw-bolder fs-5 text-gray-600">Create Jump Logic</h3>
               </div>
               <div class="card-toolbar m-0" @click="showjump=false"><i class="fa fa-angle-down fa-lg"></i></div>
               <!--end::Card title-->
            </div>
            <!--begin::Card header-->
            <!--begin::Content-->
            <div class="card-body border-top p-9">
               <!--begin::Input group-->
               <div class="row mb-6">
                  <!--begin::Label-->
                  <label class="col-lg-2 col-form-label fw-bold fs-6">if</label>
                  <!--end::Label-->
                  <!--begin::Col-->
                  <div class="col-lg-10">
                     <select name="country" class="form-select form-select-lg fw-bold select2-hidden-accessible"  @change="addanswer(logic_array.question_id)"  v-model="logic_array.question_id">
                        <option value disabled selected>select</option>
                        <option v-for="(q,index) in getPowerTypeQuestion" :value="q.id" :key="index">{{q.question}}</option>
                     </select>
                  </div>
                  <!--end::Col-->
               </div>
               <div v-if="logic_array.question_id">
                  <div class="row mb-4 mt-10" v-for="(l,lindex) in logic_array.logic_answer" :key="lindex">
                     <label class="col-lg-2 col-form-label fw-bold fs-6">{{lindex==0 ? 'Answer' : 'Or'}}</label>
                     <div class="col-lg-10">
                        <select name="country" class="form-select form-select-lg fw-bold select2-hidden-accessible" @change="addnewfield(l.id)" v-model="l.id">
                           <option v-for="(al,alindex) in logic_array.inputs" :key="alindex" :value="al.id">{{al.field_label}}</option>
                        </select>
                     </div>
                  </div>
               </div>
               <!-- <div class="row mb-4">
                  <label class="col-lg-2 col-form-label fw-bolder text-muted fs-6">or</label>
                  <div class="col-lg-10">
                      <select name="country" class="form-select form-select-lg fw-bold select2-hidden-accessible">
                      </select>
                  </div>
                  </div> -->
               <div v-if="logic_array.question_id">
                  <div class="separator separator-solid mt-20"></div>
                  <div class="row mb-4 mt-10">
                     <!--begin::Label-->
                     <label class="col-lg-2 col-form-label fw-bold fs-6">Jump</label>
                     <!--end::Label-->
                     <!--begin::Col-->
                     <div class="col-lg-10" >
                        <select class="form-select form-select-lg fw-bold select2-hidden-accessible" v-model="logic_array.jump_question_id" @change="updatejmpquestion(logic_array.jump_question_id)">
                           <option v-for="(q,index) in getAllQuestion" :key="index" :value="q.id">{{q.question}}</option>
                        </select>
                     </div>
                     <!--end::Col-->
                  </div>
                  <div class="separator separator-solid mt-20"></div>
                  <div class="row mb-4 mt-10">
                     <!--begin::Label-->
                     <label class="col-lg-2 col-form-label fw-bold fs-6">Next Action</label>
                     <!--end::Label-->
                     <!--begin::Col-->
                     <div class="col-lg-10" >
                        <select class="form-select form-select-lg fw-bold select2-hidden-accessible" v-model="logic_array.next_action_type">
                           <option v-for="(q,index) in next_question_array" :key="index" :value="q.id">{{q.label}}</option>
                        </select>
                     </div>
                     <!--end::Col-->
                  </div>
                  <div class="row mb-4 mt-10" v-if="logic_array.next_action_type === 2">
                     <!--begin::Label-->
                     <label class="col-lg-2 col-form-label fw-bold fs-6">Next Question</label>
                     <!--end::Label-->
                     <!--begin::Col-->
                     <div class="col-lg-10" >
                        <select class="form-select form-select-lg fw-bold select2-hidden-accessible" v-model="logic_array.next_question_id" @change="updatenextQuestion(logic_array.next_question_id)">
                           <option v-for="(q,index) in getAllQuestion" :key="index" :value="q.id">{{q.question}}</option>
                        </select>
                     </div>
                     <!--end::Col-->
                  </div>
               </div>
               <!--end::Input group-->
            </div>
            <!--end::Card body-->
            <!--begin::Actions-->
            <div class=" d-flex justify-content-end py-6 px-9">
               <button type="reset" class="btn btn-light btn-active-light-primary me-2" @click="cancle()">Cancel</button>
               <button type="submit" class="btn btn-primary" id="kt_account_profile_details_submit" @click="add" :disabled="!logic_array.question_id || !logic_array.jump_question_id">Save</button>
            </div>
            <div>
               <!--end::Form-->
            </div>
            <!--end::Content-->
         </div>
      </div>
   </div>
</template>
<style>
   .white-space-pre {
   white-space: pre;
   }
   .border-right-color {
   border-right: 2px solid #eef2f5;
   }
   .right-arrow{
   position: absolute;
   top: 15px;
   right: 21px;
   }
   @media only screen and (max-width: 575px) {
   .border-right-color{
   border-right: 0px !important;
   }
   .border-bottom-color{
   border-bottom: 2px solid #eef2f5;
   padding-bottom: 8px;
   padding-top: 8px;
   }
   .margin-bottom-rs{
   margin-bottom: 15px;
   };
   }
   @media only screen and (max-width: 768px) {
   .border-right-color{
   border-right: 0px !important;
   }
   .border-bottom-color{
   border-bottom: 2px solid #eef2f5;
   padding-bottom: 8px;
   padding-top: 8px;
   }
   .margin-bottom-rs{
   margin-bottom: 15px;
   };
   }
   .button-delete{
          position: absolute;
         right: 10px;
         bottom: 9px;
   }
</style>
<script>
   import { defineComponent } from "vue";
     import { mapActions, mapGetters } from "vuex"
     import { useToast } from "vue-toastification";
import question from "@/mixin/question";
     export default defineComponent({
         name: "Automation",
         props: {
             quizData: {
                 type: Object,
             },
         },
         setup() {
             const toast = useToast();
             return {toast};
          },
         computed: {
             ...mapGetters({
                 getPowerTypeQuestion: 'Quiz/getPowerTypeQuestion',
                 getAllQuestion: 'Quiz/getAllQuestion',
                 getallJumpData:'Quiz/getallJumpData'
             }),
            hiddenQuestions(){
               return this.getAllQuestion.filter(question => question.is_hidden == 1)
            }
         },
         data() {
             return {
                 showjump:false,
                 isedit:false,
                 edit_index:0,
                 quiz_id:this.$route.params.id,
                 jump_main_array:[],
                 next_question_array:[
                    {
                       label:'Go To Next Question',
                       id:1
                    },
                    {
                       label:'Go To Question #',
                       id:2
                    },
                    {
                       label:'End Quiz',
                       id:3
                    }
                 ],
                 logic_array:{
                    uniq_id:'',
                    quiz_id:this.quiz_id,
                     question_id:'',
                     question:"",
                     inputs:[],
                     jump_question_id:'',
                     jump_question:'',
                     next_action_type:'',
                     next_question_id:'',
                     next_question:'',
                     logic_answer:[
                         {
                             id:'',
                             field_label:''
                         }
                     
                     ]
                 }
             }
         },
         created(){
            this.getJumpData(this.quiz_id).then((res)=>{
                  if(res.data.status==true){
                     this.jump_main_array=res.data.data
                  }
            })
             this.getPowerTypes(this.quiz_id)
             this.getallQuestionAction(this.quiz_id)
         },
         methods: {
             ...mapActions({
                 getPowerTypes: 'Quiz/getPowerTypes',
                 getallQuestionAction:'Quiz/getallQuestionAction',
                 saveJumpLogic:'Quiz/saveJumpLogic',
                 getJumpData:'Quiz/getJumpData',
                 deleteJumpLogic:'Quiz/deleteJumpLogic'
             }),
             clearmainObject(){
                 this.logic_array={
                     uniq_id:'',
                     quiz_id:this.quiz_id,
                     question_id:'',
                     question:"",
                     question_type:'',
                     inputs:[],
                     jump_question_id:'',
                     jump_question:'',
                     next_action_type:'',
                     next_question_id:'',
                     next_question:'',
                     logic_answer:[
                         {
                             id:'',
                             field_label:''
                         }
                     
                     ]
                 }
             },
             addnewfield(q){
                 let aresult = this.logic_array.inputs.find(obj => {
                             return obj.id === q
                  })
                  if(aresult){
                     let index = this.logic_array.logic_answer.findIndex((item) => item.id === q);
                     if(index != -1){
                         this.logic_array.logic_answer[index].field_label=aresult.field_label
                     }
                  }
                 if(this.logic_array.inputs.length > this.logic_array.logic_answer.length){
                     this.logic_array.logic_answer.push({id:'',field_label:''})
                 }
             },
             cancle(){
                 this.clearmainObject()
                 this.showjump=false
                 this.isedit=false
             },
             add(){
                var withvalue = this.logic_array.logic_answer.filter(function (el) {
                  return el.id != '' && el.field_label != '';
                  });
                  this.logic_array.logic_answer=withvalue
                  if(this.logic_array.id){
                        this.jump_main_array[this.edit_index]=this.logic_array
                  }else{
                      this.logic_array.uniq_id=Math.floor(Math.random() * Date.now());
                     this.jump_main_array.push(this.logic_array)
                  }
                  this.saveJumpLogic(this.logic_array).then((res)=>{
                     if(res.data.status==true){
                        if(res.data.data.uniq_id){
                              let index = this.jump_main_array.findIndex((item) => item.uniq_id == res.data.data.uniq_id);
                              this.jump_main_array[index].id=res.data.data.id
                        }
                     }
                  })
                  this.clearmainObject()
                 this.showjump=false
                 this.isedit=false
             },
             updatejmpquestion(u){
                  var result = this.getAllQuestion.find(obj => {
                     return obj.id === u
                 })
                 if(result){
                    this.logic_array.jump_question=result.question
                 }
             },
             updatenextQuestion(u){
                  var result = this.getAllQuestion.find(obj => {
                     return obj.id === u
                 })
                 if(result){
                    this.logic_array.next_question=result.question
                 }
             },
             addNewCondition(){
                  this.showjump=true
                  this.isedit=false
                 this.clearmainObject()
             },
             editjump(data,index){
                if(!data.id){
                   return
                }
                this.logic_array={
                     id:data.id,
                     quiz_id:this.quiz_id,
                     question_id:data.question_id,
                     question:data.question,
                     inputs:data.inputs,
                     jump_question_id:data.jump_question_id,
                     jump_question:data.jump_question,
                     next_action_type:data.next_action_type,
                     next_question_id:data.next_question_id,
                     next_question:data.next_question,
                }
                this.edit_index=index
                let logic_array=[]
                for (let i in data.logic_answer) {
                        logic_array.push(data.logic_answer[i])
                  }
               if(logic_array.length == 0){
                        logic_array.push({
                             id:'',
                             field_label:''
                         })
               }
               // console.log(logic_array)
                this.logic_array.logic_answer=logic_array
                  this.showjump=true
                  this.isedit=true
             },
             deleteJump(j,index){
                  if(this.edit_index==index){
                     this.showjump=false
                  }
                  this.jump_main_array.splice(index,1);
                  this.deleteJumpLogic(j.id)
             },
             addanswer(q){
                if(this.isedit==true)var id=this.logic_array.id
                  this.clearmainObject()
                  this.logic_array.question_id=q
                  if(this.isedit==true)this.logic_array.id=id   
                 var result = this.getPowerTypeQuestion.find(obj => {
                     return obj.id === q
                 })
                 if(result){
                  console.log('ressss',result)
                 this.logic_array.question=result.question
                 this.logic_array.question_type=result.type
                 this.logic_array.inputs=result.inputs
                 }
             }
         }
     });
</script>   