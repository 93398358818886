<template>
    <!-- <div class="h3 mt-8 mb-7 text-dark fw-bolder my-0 fs-3">
        Publish
    </div> -->
    <div class="row gx-9">
        <div class="col-xl-6">
            <div class="card  h-xl-100 flex-row flex-stack flex-wrap p-6 pb-12">
                <div class="d-flex flex-column py-2">
                    <div class="d-flex align-items-center fs-4 fw-bolder text-black">Quiz URL</div>
                    <div class="d-flex align-items-center fs-7 text-muted fw-bold text-grey-400  mb-5">Public URL with your Settings</div>
                </div>
                <div class="notice d-flex bg-light-dark rounded border-primary border border-dashed flex-shrink-0 p-6" style="width:95%">
                    <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.3" d="M19.0687 17.9688H11.0687C10.4687 17.9688 10.0687 18.3687 10.0687 18.9688V19.9688C10.0687 20.5687 10.4687 20.9688 11.0687 20.9688H19.0687C19.6687 20.9688 20.0687 20.5687 20.0687 19.9688V18.9688C20.0687 18.3687 19.6687 17.9688 19.0687 17.9688Z" fill="black"></path>
                            <path d="M4.06875 17.9688C3.86875 17.9688 3.66874 17.8688 3.46874 17.7688C2.96874 17.4688 2.86875 16.8688 3.16875 16.3688L6.76874 10.9688L3.16875 5.56876C2.86875 5.06876 2.96874 4.46873 3.46874 4.16873C3.96874 3.86873 4.56875 3.96878 4.86875 4.46878L8.86875 10.4688C9.06875 10.7688 9.06875 11.2688 8.86875 11.5688L4.86875 17.5688C4.66875 17.7688 4.36875 17.9688 4.06875 17.9688Z" fill="black"></path>
                        </svg>
                    </span>
                    <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                        <div class="mb-3 mb-md-0 fw-bold">
                            <h4 class="text-gray-900 fw-bolder">Quiz URL</h4>
                            <div class="fs-6 text-gray-700 pe-7">This URL will display your with all you settings</div>
                        </div>
                        <button class="btn btn-primary px-6 align-self-center text-nowrap copy-link-btn" @click="copyLink">Copy Link</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6">
            <div class="card  h-xl-100 flex-row flex-stack flex-wrap p-6 pb-12">
                <div class="d-flex flex-column py-2">
                    <div class="d-flex align-items-center fs-4 fw-bolder text-black">Lightbox Script</div>
                    <div class="d-flex align-items-center fs-7 text-muted fw-bold text-grey-400  mb-5">Quiz Presented in a modular popup</div>
                </div>
                <div class="notice d-flex bg-light-dark rounded border-primary border border-dashed flex-shrink-0 p-6" style="width:95%">
                    <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.3" d="M19.0687 17.9688H11.0687C10.4687 17.9688 10.0687 18.3687 10.0687 18.9688V19.9688C10.0687 20.5687 10.4687 20.9688 11.0687 20.9688H19.0687C19.6687 20.9688 20.0687 20.5687 20.0687 19.9688V18.9688C20.0687 18.3687 19.6687 17.9688 19.0687 17.9688Z" fill="black"></path>
                            <path d="M4.06875 17.9688C3.86875 17.9688 3.66874 17.8688 3.46874 17.7688C2.96874 17.4688 2.86875 16.8688 3.16875 16.3688L6.76874 10.9688L3.16875 5.56876C2.86875 5.06876 2.96874 4.46873 3.46874 4.16873C3.96874 3.86873 4.56875 3.96878 4.86875 4.46878L8.86875 10.4688C9.06875 10.7688 9.06875 11.2688 8.86875 11.5688L4.86875 17.5688C4.66875 17.7688 4.36875 17.9688 4.06875 17.9688Z" fill="black"></path>
                        </svg>
                    </span>
                    <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                        <div class="mb-3 mb-md-0 fw-bold">
                            <h4 class="text-gray-900 fw-bolder">Lightbox Script</h4>
                            <div class="fs-6 text-gray-700 pe-7">Quiz that is Presented in a modular popup</div>
                        </div>
                        <button class="btn btn-primary px-6 align-self-center text-nowrap copy-script-btn" @click="copyScript">Copy Script</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent} from "vue";
import { useToast } from "vue-toastification";
export default defineComponent({
  props: {
    quizData: {
      type: Object,
    },
  },
setup() {
    const toast = useToast();
    return { toast};
  },
  methods: {
      copyLink(){
        let copyUrl = null
        if (this.quizData.links && this.quizData.links.keyword) {
            copyUrl = process.env.VUE_APP_API_URL+"/"+this.quizData.links.keyword
        }else {
            copyUrl = process.env.VUE_APP_API_URL+"/page/quiz/"+this.quizData.unique_code
        }
        document.addEventListener('copy', function(e) {
        e.clipboardData.setData('text/plain', copyUrl);
            e.preventDefault();
        }, true);
        document.execCommand('copy');
        this.toast.success('Link Copied Successfully','Copied!');
      },
      
      copyScript(){
        let copyUrl = process.env.VUE_APP_API_URL+"/page/quiz/"+this.quizData.unique_code
        var scriptRoute =  process.env.VUE_APP_API_URL+'/assets/quiz/script.js'
        // eslint-disable-next-line no-useless-escape
        var iFrame = '<iframe id="QuizForma-'+this.quizData.unique_code+'" onload="window.parent.scrollTo(0,0)" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src="'+copyUrl+'" frameborder="0" width="100%" height="539px" scrolling="no"></iframe><script type="text/javascript" src="'+scriptRoute+'" quiz-unique-code="'+this.quizData.unique_code+'"></\script>'
        document.addEventListener('copy', function(e) {
            e.clipboardData.setData('text/plain', iFrame);
            e.preventDefault();
        }, true);
        document.execCommand('copy');
        this.toast.success('Script Copied Successfully','Copied!');
      }
  },
});
</script>
