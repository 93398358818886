<template>
    <!-- <div class="h3 mt-8 mb-7 text-dark fw-bolder my-0 fs-3">
        Templates
    </div> -->
    <div class="row g-xxl-9">
        <div class="col-md-8 col-lg-8 col-sm-12">
            <div class="card card-xxl-stretch mb-5 mb-xl-10 rounded-0">
                <div class="card-header position-relative">
                    <div class="card-title">
                        <h3>Templates
                        </h3>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row g-4">

                        <div class="col-md-4" v-for="(tem,index) in getTemplate" :key="index">
                            <div class="card-xl-stretch me-md-6 template-card" :class="selected_template != tem.id ? 'border-box' : '' " id="template_1"
                                @click="CheckTemplate(tem)">
                                <a class="d-block overlay">
                                    <!-- <div
                                        class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px border border-primary border-active border-3 template-1-back" :style="{'background-image': 'url(' + require('@/assets/images/img/template_1.png') + ')'}">
                                    </div> -->
                                     <div
                                        class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px border border-primary border-active border-3 template-1-back" :class="selected_template == tem.id ? 'active' : ''" :style="{'background-image': 'url('+tem.thumbnail_image+')'}">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 col-lg-4 col-sm-12 position-relative" id="template_data">
            <form id="template_update_form" method="post"
                action="https://app.quizforma.com/quiz/details/template/update" novalidate=""
                enctype="multipart/form-data">
                <input type="hidden" name="_token" value="gikx0Umz6eftPQxukLGeTELu1HZVsZ9mpWAciJsA"> <input
                    type="hidden" name="quiz_id" value="121">
                <input type="hidden" name="template_id" value="3">
                <div class="card card-xxl-stretch mb-5 mb-xl-10 rounded-0">
                    <div class="card-header">
                        <div class="card-title">
                            <h3>Options</h3>
                        </div>
                    </div>
                    <div class="card-body pt-0 pb-1">
                        <div class="accordion accordion-icon-toggle mt-7" id="kt_accordion_2">
                            <div class="mb-5">
                                <div class="accordion-header py-3 d-flex collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#kt_accordion_2_item_1">
                                    <span class="accordion-icon"><span class="svg-icon svg-icon-primary svg-icon-xl">

                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                                viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                    <path
                                                        d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                        fill="#000000" fill-rule="nonzero"></path>
                                                    <path
                                                        d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                        fill="#000000" fill-rule="nonzero" opacity="0.3"
                                                        transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)">
                                                    </path>
                                                </g>
                                            </svg>

                                        </span></span>
                                    <h3 class="fs-6 fw-bold mb-0 ms-9">Quiz Background</h3>
                                </div>
                                <div id="kt_accordion_2_item_1" class="fs-6 collapsed collapse"
                                    data-bs-parent="#kt_accordion_2">
                                    <div id="background_div">
                                        <div class="d-flex justify-content-between">
                                            <div class="d-flex mt-5">
                                                <span class="form-check form-check-custom form-check-solid">
                                                    <input class="form-check-input w-20px h-20px" type="radio"
                                                        name="bg_type" v-model="theme.bg_type" id="bg_image_status" value="image">
                                                    <label class="form-label m-0 ms-4 me-4" for="bg_type">
                                                        Image
                                                    </label>
                                                </span>
                                                <span class="form-check form-check-custom form-check-solid">
                                                    <input class="form-check-input w-20px h-20px" type="radio"
                                                        name="bg_type" id="bg_color_status" v-model="theme.bg_type" value="color" checked="">
                                                    <label class="form-label m-0 ms-4" for="color">
                                                        Color
                                                    </label>
                                                </span>
                                            </div>
                                            <div class="preview_div">
                                                <img id="bg_image_preview"
                                                    :src="url" style="height: 50px;width: 80px;" class="page_speed_992890948" v-if="theme.bg_type=='image'">
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-lg-12"  v-if="theme.bg_type=='image'" id="bg_image_div">
                                                <span class="mb-3">
                                                    <label class="form-label fw-bold  fs-7 custom-label-form ">
                                                        Background Image
                                                    </label>
                                                    <input type="file" name="bg_image" ref="fileInput" id="bg_input"
                                                        class="form-control" @change="onFileChange">
                                                </span>
                                                <small class="text-danger image-error"></small>
                                            </div>
                                            <div class="col-lg-6 mt-3"  v-else id="bg_color_div">
                                                <span class="">
                                                    <label class="form-label fw-bold  fs-7 custom-label-form ">
                                                        Background color
                                                    </label>
                                                    <div class="color-picker d-flex">
                                                        <input type="text" class="text-gray-600"
                                                            v-model="theme.bg_color">
                                                        <input type="color" id="bg_colorpicker" name="bg_color"
                                                            v-model="theme.bg_color">
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="separator separator-solid my-6"></div>
                            <div class="mb-5">
                                <div class="accordion-header py-3 d-flex collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#kt_accordion_2_item_7">
                                    <span class="accordion-icon"><span class="svg-icon svg-icon-primary svg-icon-xl">

                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                                viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                    <path
                                                        d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                        fill="#000000" fill-rule="nonzero"></path>
                                                    <path
                                                        d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                        fill="#000000" fill-rule="nonzero" opacity="0.3"
                                                        transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)">
                                                    </path>
                                                </g>
                                            </svg>

                                        </span></span>
                                    <h3 class="fs-6 fw-bold mb-0 ms-9">Quiz Color</h3>
                                </div>
                                <div id="kt_accordion_2_item_7" class="fs-6 collapsed collapse"
                                    data-bs-parent="#kt_accordion_2">
                                    <div id="background_div">
                                        <div class="row mt-4">
                                        <div class="col-lg-6 ps-5">
                                            <span class="">
                                                <label class="form-label fw-bold  fs-7 custom-label-form ">
                                                    Bar color
                                                </label>
                                                <div class="color-picker d-flex">
                                                    <input type="text" class="text-gray-600" v-model="theme.main_color">
                                                    <input type="color" 
                                                         v-model="theme.main_color">
                                                </div>
                                            </span>
                                        </div>
                                        <div class="col-lg-6">
                                            <span class="">
                                                <label class="form-label fw-bold  fs-7 custom-label-form ">
                                                    Hover color
                                                </label>
                                                <div class="color-picker d-flex">
                                                    <input type="text" class="text-gray-600"
                                                         v-model="theme.hover_color">
                                                    <input type="color"
                                                    v-model="theme.hover_color">
                                                </div>
                                            </span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="separator separator-solid my-6"></div>
                            <div class="mb-5">
                                <div class="accordion-header py-3 d-flex collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#kt_accordion_2_item_4">
                                    <span class="accordion-icon"><span class="svg-icon svg-icon-primary svg-icon-xl">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                                viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                    <path
                                                        d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                        fill="#000000" fill-rule="nonzero"></path>
                                                    <path
                                                        d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                        fill="#000000" fill-rule="nonzero" opacity="0.3"
                                                        transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)">
                                                    </path>
                                                </g>
                                            </svg>

                                        </span></span>
                                    <h3 class="fs-6 fw-bold mb-0 ms-9">Headline Styles</h3>
                                </div>
                                <div id="kt_accordion_2_item_4" class="collapse fs-6 " data-bs-parent="#kt_accordion_2">
                                    <div class="row">
                                        <div class="mb-5 mt-5 col-xl-6 col-md-6 col-sm-12 col-lg-6 fv-plugins-icon-container"><label for="exampleFormControlInput1" class="form-label fs-7">Select Font</label>
                                            <select class="form-select" v-model="theme.headline_font">
                                                <option>select font</option>
                                                <option v-for="(f,index) in font_array" :key="index" :value="f">{{f}}</option>
                                            </select>
                                        </div>
                                        <div class="mb-5 mt-6 col-xl-6 col-md-6 col-sm-12 col-lg-6 fv-plugins-icon-container"><label for="exampleFormControlInput1" class="form-label fs-7">Select size (px)</label>
                                            <select class="form-select" v-model="theme.headline_size">
                                                <option>select size</option>
                                                <option v-for="(i,index) in size_array" :key="index">{{i}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="mb-5 col-md-6 col-xl-6 col-sm-12 fv-plugins-icon-container">
                                            <label for="exampleFormControlInput1" class="form-label fs-7">Select style</label>
                                            <select class="form-select" v-model="theme.headline_style">
                                                <option>select style</option>
                                                <option v-for="(s,index) in style_array" :key="index" :value="s.id">{{s.value}}</option>
                                            </select>
                                        </div>
                                        <div class="mb-5 col-lg-6 col-md-6 col-xl-6 col-sm-12">
                                            <span class="">
                                                <label class="form-label fw-bold  fs-7 custom-label-form ">
                                                    Headline color
                                                </label>
                                                <div class="color-picker d-flex">
                                                    <input type="text" class="text-gray-600" v-model="theme.headline_color">
                                                    <input type="color"
                                                    v-model="theme.headline_color">
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="separator separator-solid my-6"></div>
                            <div class="mb-5">
                                <div class="accordion-header py-3 d-flex collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#kt_accordion_2_item_5">
                                    <span class="accordion-icon"><span class="svg-icon svg-icon-primary svg-icon-xl">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                                viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                    <path
                                                        d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                        fill="#000000" fill-rule="nonzero"></path>
                                                    <path
                                                        d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                        fill="#000000" fill-rule="nonzero" opacity="0.3"
                                                        transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)">
                                                    </path>
                                                </g>
                                            </svg>
                                        </span></span>
                                    <h3 class="fs-6 fw-bold mb-0 ms-9">Description Styles</h3>
                                </div>
                                <div id="kt_accordion_2_item_5" class="collapse fs-6 " data-bs-parent="#kt_accordion_2">
                                        <div class="row">
                                            <div class="mb-5 mt-5 col-xl-6 col-md-6 col-sm-12 col-lg-6 fv-plugins-icon-container"><label for="exampleFormControlInput1" class="form-label fs-7">Select Font</label>
                                                <select class="form-select" v-model="theme.description_font">
                                                    <option>select font</option>
                                                    <option v-for="(f,index) in font_array" :key="index" :value="f">{{f}}</option>
                                                </select>
                                            </div>
                                            <div class="mb-5 mt-6 col-xl-6 col-md-6 col-sm-12 col-lg-6 fv-plugins-icon-container"><label for="exampleFormControlInput1" class="form-label fs-7">Select size (px)</label>
                                                <select class="form-select" v-model="theme.description_size">
                                                    <option>select size</option>
                                                    <option v-for="(i,index) in size_array" :key="index">{{i}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="mb-5 col-xl-6 col-md-6 col-sm-12 col-lg-6 fv-plugins-icon-container"><label for="exampleFormControlInput1" class="form-label fs-7">Select style</label>
                                                <select class="form-select" v-model="theme.description_style">
                                                    <option>select style</option>
                                                    <option v-for="(s,index) in style_array" :key="index" :value="s.id">{{s.value}}</option>
                                                </select>
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-sm-12 col-lg-6">
                                                <span class="">
                                                    <label class="form-label fw-bold  fs-7 custom-label-form ">
                                                        Description color
                                                    </label>
                                                    <div class="color-picker d-flex">
                                                        <input type="text" class="text-gray-600" v-model="theme.description_color">
                                                        <input type="color"
                                                        v-model="theme.description_color">
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div class="separator separator-solid my-6"></div>
                            <div class="mb-5">
                                <div class="accordion-header py-3 d-flex collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#kt_accordion_2_item_6">
                                    <span class="accordion-icon"><span class="svg-icon svg-icon-primary svg-icon-xl">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                                viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                    <path
                                                        d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                        fill="#000000" fill-rule="nonzero"></path>
                                                    <path
                                                        d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                        fill="#000000" fill-rule="nonzero" opacity="0.3"
                                                        transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)">
                                                    </path>
                                                </g>
                                            </svg>
                                        </span></span>
                                    <h3 class="fs-6 fw-bold mb-0 ms-9">Regular Text Styles</h3>
                                </div>
                                <div id="kt_accordion_2_item_6" class="collapse fs-6 " data-bs-parent="#kt_accordion_2">
                                        <div class="row">
                                            <div class="mb-5 mt-5 col-xl-6 col-md-6 col-sm-12 col-lg-6 fv-plugins-icon-container"><label for="exampleFormControlInput1" class="form-label fs-7">Select Font</label>
                                                <select class="form-select" v-model="theme.regular_text_font">
                                                    <option>select font</option>
                                                    <option v-for="(f,index) in font_array" :key="index" :value="f">{{f}}</option>
                                                </select>
                                            </div>
                                            <div class="mb-5 mt-6 col-xl-6 col-md-6 col-sm-12 col-lg-6 fv-plugins-icon-container"><label for="exampleFormControlInput1" class="form-label fs-7">Select size</label>
                                                <select class="form-select" v-model="theme.regular_text_size">
                                                    <option>select size (px)</option>
                                                    <option v-for="(i,index) in size_array" :key="index">{{i}}</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="mb-5 col-xl-6 col-md-6 col-sm-12 col-lg-6fv-plugins-icon-container"><label for="exampleFormControlInput1" class="form-label fs-7">Select style</label>
                                                <select class="form-select" v-model="theme.regular_text_style">
                                                    <option>select style</option>
                                                    <option v-for="(s,index) in style_array" :key="index" :value="s.id">{{s.value}}</option>
                                                </select>
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-sm-12 col-lg-6">
                                                <span class="">
                                                    <label class="form-label fw-bold  fs-7 custom-label-form ">
                                                        Regular text color
                                                    </label>
                                                    <div class="color-picker d-flex">
                                                        <input type="text" class="text-gray-600" v-model="theme.regular_text_color">
                                                        <input type="color"
                                                        v-model="theme.regular_text_color">
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex border-0 justify-content-end"><button type="button"
                           @click="savthemeData()" :disabled="loader" class="btn btn-primary btn-sm">Update</button></div>
                </div>
            </form>
        </div>
    </div>
</template>
<style>
        .custom-nav {
                padding: 10px 20px 10px 20px !important;
                font-size: 13px;
                border: 1px solid #fff !important;
                color: #000000;
            }
    
            .custom-top-bar.active {
                background: #fff;
                padding: 5px 10px 5px 10px;
                border: 1px solid #fff;
                border-radius: 5px;
            }
    
            .custom-nav.active {
                color: #6794DC !important;
            }
    
            .main-label {
                position: relative;
            }
    
            .custom-input {
                top: 28px;
                position: absolute;
                left: 9px;
                width: 17px;
                height: 17px;
            }
    
            .plus-button {
                border-radius: 5px;
                border: 1px dashed #04C8C8;
                height: 50px;
                width: 60px;
            }
    
            .color-box {
                color: #04c8c8;
                background-color: #F3F5F6 !important;
                border: 1px solid;
                border-radius: 0px;
                border-color: #04c8c8;
            }
    
            .color-picker input[type="text"] {
                height: 38px;
                width: 70px;
                padding-top: 1px;
                padding-bottom: 1px;
                text-align: center;
                border: 1.5px solid;
                border-right: 0px;
            }
    
            .color-picker input[type="color"] {
                height: 38px;
                width: 38px;
                padding: 0;
                border: 0;
            }
    
            .checkbox-checked {
                color: #04C8C8 !important;
                border-color: #04C8C8 !important;
                background-color: #F3F5F6 !important;
            }
    
            .detail-box {
                border-radius: 0.475rem 0rem 0rem 0.475rem !important;
            }
            
</style>
<script>
import { defineComponent } from "vue";
import {mapActions ,mapGetters} from 'vuex'
import { useToast } from "vue-toastification";

export default defineComponent({
     setup() {
       const toast = useToast();
        return {toast};
    },
 props:{
     quizData:{
         type:Object
     }
 },
data() {
   return {
       selected_template:this.quizData.template,
       loader:false,
       selected_file:'',
       url:"",
       font_array:['Roboto','Open Sans','Lato','Montserrat','Oswald','Source Sans Pro','Slabo 27px','Slabo 13px','Raleway','PT Sans','Merriweather','Noto Sans','Noto Serif','Nunito Sans','Concert One','Prompt','Work Sans'],
       size_array:[18,24,30,36,42,48],
       style_array:[
           {
             id:'font-weight: bold !important',
             value:'Bold'
           },
           {
             id:'font-style: italic !important',
             value:'Italic'
           },
           {
             id:'text-decoration: overline !important',
             value:'Overline'
           },
           {
             id:'text-decoration: line-through !important',
             value:'Line Through'
           },
           {
             id:'text-decoration: underline !important',
             value:'Underline'
           },
           {
             id:'text-decoration: underline overline !important',
             value:'Underline Overline'
           },
           {
             id:'font-style: oblique !important',
             value:'Oblique'
           },
           ],
       theme:{
       }
    }
    },
    watch:{
        quizData(){
            this.selected_template=this.quizData.template
        }
    },
    computed:{
        ...mapGetters({
            getTemplate:'Quiz/getTemplate'
        })
    },
    methods:{
        ...mapActions({
            getallTemplate:'Quiz/getallTemplate',
            getQuizTemplate:'Quiz/getQuizTemplate',
            saveTheme:'Quiz/saveTheme',
            updateTemplate:'Quiz/updateTemplateId'
        }),
        CheckTemplate(data){
            this.selected_template=data.id
            this.checkTemplateexist(data.id)
        },
        onFileChange(e) {
        const file = e.target.files[0];
        if(file){
            if(file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/svg' || file.type == 'image/svg+xml'){
                const fileSize = Math.round((file.size / 1024));
                if(fileSize >= 4096){
                    this.$refs.fileInput.value=''
                     this.$swal.fire(
                        "Error!",
                        "Please enter image less then 4mb",
                        "error"
                      );
                      return
                }
                this.selected_file=file
                this.url = URL.createObjectURL(file);
            }else{
                this.$refs.fileInput.value=''
                 this.$swal.fire(
                        "Error!",
                        "Please enter valid image file.",
                        "error"
                      );
            }

        }
        },
        savthemeData(){
            var formdata= new FormData();
            formdata.append('quiz_id',this.quizData.id)
            formdata.append('template_id',this.selected_template)
            formdata.append('bg_image',this.selected_file)
            formdata.append('bg_type',this.theme.bg_type)
            formdata.append('bg_color',this.theme.bg_color)
            formdata.append('headline_color',this.theme.headline_color)
            formdata.append('subheadline_color',this.theme.subheadline_color)
            formdata.append('main_color',this.theme.main_color)
            formdata.append('hover_color',this.theme.hover_color)
            formdata.append('headline_font',this.theme.headline_font)
            formdata.append('headline_size',this.theme.headline_size)
            formdata.append('headline_style',this.theme.headline_style)
            formdata.append('description_color',this.theme.description_color)
            formdata.append('description_font',this.theme.description_font)
            formdata.append('description_size',this.theme.description_size)
            formdata.append('description_style',this.theme.description_style)
            formdata.append('regular_text_color',this.theme.regular_text_color)
            formdata.append('regular_text_font',this.theme.regular_text_font)
            formdata.append('regular_text_size',this.theme.regular_text_size)
            formdata.append('regular_text_style',this.theme.regular_text_style)
            this.saveTheme(formdata).then(()=>{
                    this.toast.success("Updated Successfully");
            }).catch((error)=>{
                       this.toast.error(error.response.data.message);
            })
        },
        checkTemplateexist(template_id){
            this.selected_file=''
            let data={
                quiz_id:this.quizData.id,
                template_id:this.selected_template,

            }
            this.selected_template=template_id
            this.updateTemplate(template_id)
            this.getQuizTemplate(data).then((res)=>{
                console.log('aaa',res)
                    if(res.data.status==true){
                        let result=res.data.data
                        console.log('res',result)
                        this.url=result.bg_image
                        this.theme=res.data.data
                    }else{
                        let data=this.getTemplate.find(data =>data.id === template_id);
                        if(data){
                            this.theme=data
                            this.url=data.bg_image
                        }
                    }
            })
        }
    },
    async mounted(){
        await this.getallTemplate()
        await this.checkTemplateexist(this.quizData.template)
    }
});
</script>
<style>
.border-box{
    border: 1px solid #eff2f5;
    border-radius: 0.85rem;
}
</style>