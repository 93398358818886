<template>
  <div class="card q pb-9" :class="!question.db_id ? 'pointer-event-none' : ''">
    <div class="card-header align-items-center border-0 mt-3">
      <h3 class="title-div card-title align-items-start flex-column">
        <div class="options_card scoring_options_data3 tagging_options_data3">
          <span
            id="ques_options_3"
            class="fw-bolder text-dark fs-3 span-text question content-editable"
            contenteditable="true"
            @input="(event) => onInput(event, 'question')"
            @click="checkInput('question')"
            @blur="setValue('question')"
            >{{ question.question }}</span
          >
        </div>
        <div>
          <span
            id="desc_options_3"
            class="text-gray-400 mt-2 fw-bold fs-6 span-text description content-editable"
            contenteditable="true"
            @input="(event) => onInput(event, 'description')"
            @click="checkInput('description')"
            @blur="setValue('description')"
            >{{ question.description }}</span
          >
        </div>
      </h3>
      <div class="div-top-icon show-on-hover">
        <a
          href="javascript:void(0)"
          class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm me-4"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Scoring"
          v-if="pointStatus == 1"
          @click="sendActiveToMain(5)">
          <span class="svg-icon svg-icon-3 show-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" :class="checkScoring(question) == true ? 'text-success' : ''" viewBox="0 0 384 512" fill="#a1a5b7">
                      <path d="M374.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-320 320c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l320-320zM128 128A64 64 0 1 0 0 128a64 64 0 1 0 128 0zM384 384a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"/>
                  </svg>
          </span>
        </a>
        <a
          href="javascript:void(0)"
          @click="sendActiveToMain(2)"
          class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm me-4"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Scoring"
          ><span class="svg-icon svg-icon-3 show-pointer"
            ><i
              class="fas fa-flag"
              :class="checkScoring(question) == true ? 'text-success' : ''"
            ></i></span
        ></a>
        <a
          href="javascript:void(0)"
          class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm me-4"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title=""
          @click="sendActiveToMain(4)"
          data-bs-original-title="Jump Logic"
        >
          <span class="svg-icon svg-icon-3 show-pointer">
            <i
              class="fa fa-cogs fa-lg"
              :class="checkJumpLogic(question) == true ? 'text-success' : ''"
            ></i>
          </span>
        </a>
        <a
          href="javascript:void(0)"
          @click="sendActiveToMain(3)"
          class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm me-4"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Tagging"
          ><span class="svg-icon svg-icon-3 show-pointer"
            ><i
              class="fa fa-tag fa-lg"
              :class="checkTagging(question) == true ? 'text-success' : ''"
            ></i
          ></span>
        </a>
        <a
          href="javascript:void(0)"
          @click="removeQuestion"
          class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Delete Card"
        >
          <span class="svg-icon svg-icon-3 show-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                fill="black"
              ></path>
              <path
                opacity="0.5"
                d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                fill="black"
              ></path>
              <path
                opacity="0.5"
                d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                fill="black"
              ></path>
            </svg>
          </span>
        </a>
      </div>
      <div class="div-bottom-menu show-on-hover">
        <!-- <label class="form-check required-checkbox me-9 p-2"
          ><input
            v-model="question.is_multiple"
            type="checkbox"
            class="form-check-input is_required"
            name="Checkboxes1"
            @change="upMultiple"
          /><span></span>Multiple Options</label
        > -->
        <label class="form-check required-checkbox me-9 p-2"
          ><input
            v-model="question.is_required"
            type="checkbox"
            class="form-check-input is_required"
            name="Checkboxes1"
            @change="up"
          /><span></span>Required</label
        >
        <label class="form-check required-checkbox p-2" for="hidden_checkbox"
          ><input
            v-model="question.is_hidden"
            type="checkbox"
            class="form-check-input is_required"
            name="Checkboxes1"
            id="hidden_checkbox"
            @change="update_hidden(mainIndex)"
          /><span></span>Hidden</label
        >
      </div>
    </div>
    <div class="card-body pt-5">
      <div class="row" id="options_data_3">
        <div
          class="col-md-12"
          v-for="(i, index) in question.inputs"
          :key="index"
        >
          <div class="row options-row mb-10">
            <!-- <pre class="text-dark">{{ i }}</pre> -->
            <div class="col-md-2">
                <input type="file" class="d-none" :ref="`imageInput_${i.id}`" @change="onFileChange($event,i)">
                    <div class="image-block">
                    <!-- <div class="image-block-edit-icon" @click="onFileClick(`imageInput_${i.id}`)">
                        <i class="fa fa-pen" aria-hidden="true"></i>
                    </div> -->
                    <img  @click="onFileClick(`imageInput_${i.id}`)"
                        class="w-100 h-100 custom-image"
                        v-if="i.image" :src="getImage(i.image)"
                    >
                    <img  @click="onFileClick(`imageInput_${i.id}`)"
                        class="w-100 h-100 custom-image"
                        v-else :src="noImage"
                    >
                </div>
            </div>
            <div class="col-md-4 ps-0">
                <div>
                    <span
                        class="form-label fw-bolder"
                        >Image Title</span>
                    <input type="text" class="form-control form-control-solid" @input="updateQuestionInputDebounce(i)" v-model="i.field_label"/>
                </div>
            </div>
            <div class="col-md-4">
                <div>
                    <span
                        class="form-label fw-bolder"
                        >Image Description
                    </span>
                    <input type="text" class="form-control form-control-solid" @input="updateQuestionInputDebounce(i)" v-model="i.field_placeholder"/>
                </div>
            </div>
            <div
              class="col-md-2 mt-8 option-delete-btn"
              id="option_delete_icon_3_0"
            >
              <a
                v-if="index != 0"
                href="javascript:void(0)"
                class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Delete Card"
                @click="removeInput(index, i.db_id)"
              >
                <span class="svg-icon svg-icon-3 show-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                      fill="black"
                    ></path>
                    <path
                      opacity="0.5"
                      d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                      fill="black"
                    ></path>
                    <path
                      opacity="0.5"
                      d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                      fill="black"
                    ></path>
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div class="text-primary cursor-pointer fw-bold" @click="addNewInput">Add New Option</div>
      </div>
      <br /><img
        class="draggable-handle div-left-icon show-on-hover"
        :src="require('@/assets/images/img/order_drag.svg')"
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        title="Drag to change order"
      />
      <h1 class="div-left-hd show-on-hover left-count-pos-40 id">
        {{ aIndex }}
      </h1>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { defineComponent, toRefs } from "vue";
import { mapActions } from "vuex";
import QuizMixin from "@/mixin/question.js";
export default defineComponent({
  mixins: [QuizMixin],
  name: "yes-no",
  props: {
    questionData: {
      type: Object,
    },
    mainIndex: {
      type: Number,
    },
    questionTypes: {
      type: Array,
    },
    scoreStatus: {
      type : Number
    }
  },
  setup(props) {
    const { questionData: question } = toRefs(props);
    const { mainIndex: pindex } = toRefs(props);
    const { questionTypes: questionTypesData } = toRefs(props);
    const { scoreStatus : pointStatus } = toRefs(props);
    return {
      question,
      pindex,
      questionTypesData,
      pointStatus
    };
  },
  computed: {
    nextQuestion() {
      return this.questionTypesData[this.aIndex];
    },
    noImage() {
        return require('@/assets/images/img/no-image.png')
    },  
  },
  data() {
    return {
        aIndex: this.mainIndex + 1,
        assetUrl : process.env.VUE_APP_ENVIROMENT != 'local' ? process.env.VUE_APP_API_URL : "http://127.0.0.1:8000",
    };
  },
  methods: {
    ...mapActions({
      addInput: "Quiz/addInput",
      updateInputValue: "Quiz/addInputValue",
      deleteQuestionInput: "Quiz/deleteQuestionInput",
      updateQuestionInput: "Quiz/updateQuestionInput",
      updateQuestionInputImage: "Quiz/updateQuestionInputImage",
    }),
    changeOption(event, index, i) {
      if (event.target.innerText) {
        this.question.inputs[index].field_label = event.target.innerText;
        this.question.inputs[index].field_value = event.target.innerText;
      } else {
        this.question.inputs[index].field_label = "Option";
        this.question.inputs[index].field_value = "Option";
      }
      this.sendupdatedArray();
      this.UpdateInputValueI(i);
    },
    UpdateInputValueI: _.debounce(function (q) {
      this.updateInputValue(q);
    }, 500),
    addNewInput() {
      let _blank = {
        question_id: this.question.id,
        db_id: "",
        disqualify: false,
        field_label: "Add image title here",
        field_placeholder: "Add image description here",
        html_tag_type: "radio",
        id: null,
        tag_id: "",
        type: "radio",
        uniq_id: Math.floor(Math.random() * Date.now()),
        image : null
      };
      this.question.inputs.push(_blank);
      this.updateInput(_blank);
      this.sendupdatedArray();
    },
    updateInput(data) {
      let sendData = {
        ...data,
        question_id: this.question.db_id,
        quiz_id: this.$route.params.id,
      };
      this.addInput(sendData).then((response) => {
        let result = response.data.data;
        const index = this.question.inputs.findIndex((object) => {
          return object.uniq_id == result.uniq_id;
        });
        if (index || index == 0) {
          this.question.inputs[index].db_id = result.db_id;
        }
      });
    },
    removeInput(index, id) {
      this.question.inputs.splice(index, 1);
      if (id) {
        this.deleteQuestionInput(id);
      }
      this.sendupdatedArray();
    },
    removeQuestion() {
      let data = {
        index: this.pindex,
        id: this.question.db_id,
      };
      this.$emit("removequestion", data);
    },
    sendupdatedArray() {
      let data = {
        question: this.question,
        index: this.pindex,
      };
      this.$emit("updateMainArray", data);
    },
    onFileClick(refName){
        this.$refs[refName][0].click()
    },
    onFileChange(e,input) {
        const file = e.target.files[0];
        if (file) {
            if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/svg' || file.type == 'image/svg+xml') {
                input.image = URL.createObjectURL(file);
                var formdata= new FormData();
                formdata.append('input_id',input.id)
                formdata.append('image',file)
                this.updateQuestionInputImage(formdata).then((res) => {
                    if(res.data.status == true){
                        input.image = res.data.data && res.data.data.image ? res.data.data.image : null
                    }
                });
            } else {
                this.$swal.fire(
                    "Error!",
                    "Please enter valid image file.",
                    "error"
                );
            }
        }
    },
    getImage(image){
        if (image && image.search('blob:') == -1) 
            return this.assetUrl + '/storage/' +image
        else
            return image
    },
    updateQuestionInputDebounce: _.debounce(function (input) {
        let data={
            id: input.db_id,
            field_label: input.field_label,
            field_placeholder: input.field_placeholder
        }
        this.updateQuestionInput(data)
    }, 700),
  },
});
</script>
<style scoped>
.div-bottom-menu {
  position: absolute;
  right: 5px;
  /* top: 8px; */
  bottom: 8px;
  /* background-color: #FFFFFF; */
  padding-left: 35px;
}
.div-top-icon {
  position: absolute;
  right: 5px;
  top: 8px;
  /* bottom: 8px; */
  /* background-color: #FFFFFF; */
  padding-left: 35px;
}

.card:hover .title-div {
  width: 85% !important;
  word-break: break-word;
}
.btn.btn-bg-light {
  background-color: #f5f8fa;
}
.text-dark.span-text {
  color: #181c32 !important;
}
.form-select,
.form-control {
  background-color: #fff;
  border: 1px solid #e4e6ef;
  color: #181c32 !important;
}
.dropdown.show > .form-control.form-control-solid,
.form-control.form-control-solid:active,
.form-control.form-control-solid.active,
.form-control.form-control-solid:focus,
.form-control.form-control-solid.focus {
  background-color: #f5f8fa !important;
  border-color: #f5f8fa !important;
  color: #181c32 !important;
}
/* .form-check-input{
    background-color: #fff;
} */
.div-bottom-menu label {
  color: #181c32;
}
.col-form-label,
.form-label,
.form-check-label {
  color: #3f4254;
}

.options-row:not(:hover) .option-delete-btn {
  display: none !important;
}
.image-block {
  height: 75px;
  width: 110px;
  /* border-radius: 6px; */
  position: relative;
}

.image-block-edit-icon {
  position: absolute;
  color: #11132d;
  right: 2px;
  background: white;
  font-size: 15px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  padding-left: 3px;
  padding-top: 3px;
  top: 2px;
}
.custom-image{
  border-radius: 5px;
  object-fit: cover;
  cursor: pointer;
}
</style>
